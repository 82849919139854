<template>
    <div class="partners">
        <v-row style="max-width: 1400px" class="mx-auto">
            <v-col cols="12">
                <v-card align="center" justify="center" class="mx-auto elevation-6">
                    <v-toolbar class="mb-2" height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            {{ $t('partners.list_of_partners', { p1: partners.length, p2: usersCount, p3: usersCountOnline }) }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <template>
                        <v-card-title class="py-0 d-flex">
                            <v-btn outlined small text tile class="mx-2 flex-grow-0" @click="closeAll()">Свернуть</v-btn>
                            <v-btn outlined small text tile class="mx-2 flex-grow-0" @click="openAll()">Развернуть</v-btn>
                            <v-btn outlined small tile color="success" class="mx-2 flex-grow-0" @click="onClickCreate()">Создать</v-btn>

                            <v-spacer></v-spacer>

                            <v-text-field style="width: 340px; flex: initial" v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
                        </v-card-title>

                        <v-data-table
                            dense
                            no-data-text="Нет данных"
                            no-results-text="Нет данных, попробуйте изменить запрос"
                            :footer-props="{
                                pageText: '{0} - {1} из {2}',
                                itemsPerPageText: 'Показывать на странице',
                                itemsPerPageAllText: 'все',
                                showFirstLastPage: true,
                                itemsPerPageOptions: [25, 50, 100, -1],
                            }"
                            :single-expand="false"
                            show-expand
                            :headers="headers"
                            :search="search"
                            :items="partners"
                            item-key="id"
                            :loading="loading"
                            :loading-text="$t('common.loading')"
                            @update:items-per-page="updatePaginate"
                            @update:page="updatePaginate"
                            ref="dTable"
                        >

                            <template v-slot:item="{ item, expand, isExpanded }">
                                <tr @click="editFlag ? false : expand(!isExpanded)">
                                    <td :colspan="headers.length - 4">
                                        <v-btn small icon :data-open="isExpanded">
                                            <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
                                            <v-icon v-else>mdi-chevron-down</v-icon>
                                        </v-btn>

                                        <b class="ml-1">{{ item.name }}</b> - {{ item.address }}
                                        
                                        <v-chip
                                          class="mx-2 px-2"
                                          style="cursor:pointer"
                                          color="blue lighten-2"
                                          outlined
                                          label
                                          x-small
                                        >{{ item.users.length }}</v-chip>


                                        <v-btn small icon @click="onClickEdit(item)">
                                            <v-icon>mdi-home-edit-outline</v-icon>
                                        </v-btn>
                                        
                                    </td>
                                    <td>{{ item.ordersToday }}</td>
                                    <td>{{ item.ordersYesterday }}</td>
                                    <td>{{ item.ordersMonth }}</td>
                                </tr>
                            </template>

                            <template v-slot:expanded-item="{ headers, item }">
                                <tr class="expanded-content">
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <p v-for="user in item.users" :key="user.id">
                                            <v-avatar size="24" class="mr-2">
                                              <img
                                                alt="partner"
                                                :src="`${ config.api.delivery + 'user_avatar?tid=' + user.tid }`"
                                              >
                                            </v-avatar>
                                            <b>{{ user.tname.length > 30 ?  user.tname.substr(0, 30) + '...' : user.tname }}</b>
                                        </p>
                                    </td>
                                    <td>
                                        <p v-for="user in item.users" :key="user.id">
                                            <span :style="{ color: getConnectColor(user.enable) }">{{ getConnectStatus(user.enable) }}</span>
                                        </p>
                                    </td>
                                    <td>
                                        <p v-for="user in item.users" :key="user.id">
                                            <span style="width:35px; display: inline-block;">{{ user.ordersToday }}</span>
                                            <v-btn v-if="user.ordersToday" outlined text tile x-small @click="onClickTodayDetails(item, user)" ><v-icon small>mdi-menu</v-icon></v-btn>
                                        </p>
                                    </td>
                                    <td>
                                        <p v-for="user in item.users" :key="user.id">
                                            <span style="width:35px; display: inline-block;">{{ user.ordersYesterday }}</span>
                                            <v-btn v-if="user.ordersYesterday" outlined text tile x-small @click="onClickYesterdayDetails(item, user)" ><v-icon small>mdi-menu</v-icon></v-btn>
                                        </p>
                                    </td>
                                    <td>
                                        <p v-for="user in item.users" :key="user.id">
                                            <span style="width:35px; display: inline-block;">{{ user.ordersMonth }}</span>
                                            <v-btn v-if="user.ordersMonth" outlined text tile x-small @click="onClickMonthDetails(item, user)" ><v-icon small>mdi-menu</v-icon></v-btn>
                                        </p>
                                    </td>
                                </tr>

                            </template>
                        </v-data-table>
                    </template>
                </v-card>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-dialog v-model="partnerDialog" max-width="800" @click:outside="editFlag = false">
                <v-card class="partners-dialog">

                    <v-card-title class="headline grey lighten-2">
                        {{ dialogHeader }}
                    </v-card-title>

                    <v-card-text>
                        <v-form>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" class="py-0">
                                        <v-text-field v-model="partnerCopy.name" label="Наименование"></v-text-field>
                                    </v-col>

                                    <v-col cols="8" class="py-0">
                                        <v-text-field v-model="partnerCopy.address" label="Адрес забора" @input="onChangeAddress"></v-text-field>
                                    </v-col>

                                    <v-col cols="4" class="py-0 d-flex">
                                        <v-btn :disabled="addressUpdateFlag" :loading="addressUpdateFlag" elevation="2" class="float-right ma-0 pa-0 mr-2 mt-5" icon small @click="onClickAddressUpdate">
                                            <v-icon>mdi-refresh</v-icon>
                                        </v-btn>
                                        <span class="ma-0 pa-0 ml-3 mt-6" :style="{ width: '120px', color: addressColor }">{{ addressResult }}</span>
                                    </v-col>

                                    <v-col cols="12" class="pb-0">
                                        <p class="my-0">Пользователи Telegram бота</p>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="6">
                                        <v-card class="mx-auto" max-width="500">

                                            <v-toolbar color="teal" dark height="40">
                                                <v-toolbar-title>Пользователи партнера</v-toolbar-title>
                                            </v-toolbar>

                                            <v-list dense>
                                                <draggable class="list-group" :list="partnerCopy.users" group="people" @change="onChangeUser">
                                                    <v-list-item v-for="user in partnerCopy.users" :key="user.id">
                                                        <v-list-item-content class="d-block">
                                                            <v-avatar size="24" class="mr-2">
                                                              <img
                                                                alt="partner"
                                                                :src="`${ config.api.delivery + 'user_avatar?tid=' + user.tid }`"
                                                              >
                                                            </v-avatar>
                                                            <b>{{ user.tname.length > 30 ?  user.tname.substr(0, 30) + '...' : user.tname }}</b>
                                                            <span class="ml-2" :style="{ color: getConnectColor(user.enable) }">{{ getConnectStatus(user.enable).toLowerCase() }}</span>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </draggable>
                                            </v-list>

                                        </v-card>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="6">
                                        <v-card class="mx-auto" max-width="500">

                                            <v-toolbar color="teal" dark height="40">
                                                <v-toolbar-title>Нераспределенные пользователи</v-toolbar-title>
                                            </v-toolbar>

                                            <v-list dense>
                                                <draggable class="list-group" :list="unallocatedUsersCopy" group="people" @change="onChangeUser">
                                                    <v-list-item v-for="user in unallocatedUsersCopy" :key="user.id">
                                                        <v-list-item-content class="d-block">
                                                            <v-avatar size="24" class="mr-2">
                                                              <img
                                                                alt="partner"
                                                                :src="`${ config.api.delivery + 'user_avatar?tid=' + user.tid }`"
                                                              >
                                                            </v-avatar>
                                                            <b>{{ user.tname.length > 30 ?  user.tname.substr(0, 30) + '...' : user.tname }}</b>
                                                            <span class="ml-2" :style="{ color: getConnectColor(user.enable) }">{{ getConnectStatus(user.enable).toLowerCase() }}</span>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </draggable>
                                            </v-list>

                                        </v-card>
                                    </v-col>

                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="success" dark @click="onClickSave">
                            Сохранить
                        </v-btn>

                        <v-btn color="error" text @click="onClickDelete">Удалить</v-btn>
                        
                        <v-btn color="primary" text @click="partnerDialog = false">Закрыть</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>



            <v-dialog
              v-model="ordersDialog"
              width="720"
              scrollable
            >
              <v-card
              :loading="loadingOrder">

                <template slot="progress">
                  <v-progress-linear
                    indeterminate
                    absolute
                    top
                    color="deep-purple accent-4"
                  ></v-progress-linear>
                </template>

                <v-card-title class="headline grey lighten-2">
                  {{ dialogHeader }}
                </v-card-title>

                <v-card-text class="py-4">
                  <span v-html="dialogContent"></span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark @click="updateDetails">Обновить</v-btn>
                  <v-btn color="primary" text @click="ordersDialog = false">Закрыть</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>


        </div>

        <span class="pb-16 d-block" />
    </div>
</template>

<script>
    import config from '../../config'
    import draggable from 'vuedraggable'
    import moment from 'moment-timezone'

    moment.locale('ru')
    moment.tz.setDefault(config.timezoneString)

    export default {
        data() {
            return {
                config,
                loading: false,
                loadingOrder: false,
                search: '',
                partnerCopy: {},
                unallocatedUsers: {},
                unallocatedUsersCopy: {},
                partners: [],
                partner_users: [],
                usersCount: 0,
                usersCountOnline: 0,
                partnerDialog: false,
                ordersDialog: false,
                dialogHeader: '',
                dialogContent: '',
                headers: [
                    {
                        text: 'Название и адрес',
                        align: ' th-first-item',
                        value: 'name',
                    },
                    {
                        align: '1 d-none',
                        value: 'name',
                        sortable: false,
                    },
                    {
                        align: '1 d-none',
                        value: 'address',
                        sortable: false,
                    },
                    {
                        text: 'Имя в Telegram',
                        value: 'tname',
                        sortable: false,
                    },
                    {
                        text: 'Подключение',
                        value: 'enable',
                        sortable: false,
                    },
                    {
                        text: 'Заказы за сегодня',
                        value: 'ordersToday',
                    },
                    {
                        text: 'Заказы за вчера',
                        value: 'ordersYesterday',
                    },
                    {
                        text: 'Заказы за месяц',
                        value: 'ordersMonth',
                    },
                ],
                editFlag: false,
                addressUpdateFlag: false,
                addressResult: '',
                addressColor: '',
                timerAddressUpdate: null,
                createFlag: false,
                detailsPartner: 0,
                detailsDate1: '',
                detailsDate2: '',
            }
        },
        components: {
            draggable,
        },
        mounted() {
            console.log('start!')

            this.updateData()

            // setTimeout(() => {
            //     this.partners[0].name = '------'
            // }, 5000)
        },

        beforeDestroy() {},

        destroyed() {},
        watch: {},
        computed: {},
        methods: {
            onClickTodayDetails(partner, user) {
                this.dialogHeader = `${ partner.name }, ${ user.tname } - заказы за сегодня (${user.ordersToday})`
                this.detailsPartner = user.tid
                this.detailsDate1 = moment().format('YYYY-MM-DD')
                this.detailsDate2 = moment().format('YYYY-MM-DD')
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.ordersDialog = true
            },
            onClickYesterdayDetails(partner, user) {
                this.dialogHeader = `${ partner.name }, ${ user.tname } - заказы за вчера (${user.ordersToday})`
                this.detailsPartner = user.tid
                this.detailsDate1 = moment().subtract(1, 'd').format('YYYY-MM-DD')
                this.detailsDate2 = moment().subtract(1, 'd').format('YYYY-MM-DD')
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.ordersDialog = true
            },
            onClickMonthDetails(partner, user) {
                this.dialogHeader = `${ partner.name }, ${ user.tname } - заказы за месяц (${user.ordersMonth})`
                this.detailsPartner = user.tid
                this.detailsDate1 = moment().subtract(1, 'month').format('YYYY-MM-DD')
                this.detailsDate2 = moment().format('YYYY-MM-DD')
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.ordersDialog = true
            },
            updateDetails() {
                this.loadingOrder = true

                this.$store
                    .dispatch('orders_date', {
                        partner: this.detailsPartner,
                        courier: 0,
                        date1: this.detailsDate1,
                        date2: this.detailsDate2 + ' 23:59:59',
                        mode: 0
                    })
                    .then(res => {
                        this.dialogContent = ''
                        if (res.data.success) {
                            console.log('res.data.orders', res.data.orders)
                            res.data.orders.forEach((order, i) => {
                                const time = moment(order.date).format('D MMM HH:mm')
                                const delivery_time = order.date_complete ? ` (${ moment(order.date_complete).format('D MMM HH:mm') })` : ''
                                this.dialogContent += `${ i + 1 }. [${ order.id }] <b>${ order.name }</b> - ${ time } - <span style="color: ${ this.orderStatusColorHtml(order.status) };">${ this.orderStatusStr(order.status) }${ delivery_time }</span><br>`
                            })
                        } else {
                            this.dialogContent = this.$t('common.loading_error', {p1: 1})
                        }
                        this.loadingOrder = false
                    })
                    .catch(err => {
                        this.loadingOrder = false
                        console.log('partner_orders_get error', err)
                        this.dialogContent = this.$t('common.loading_error', {p1: 2})
                    })

            },
            onChangeUser() {
            },
            onClickSave() {
                this.loading = true
                this.partnerDialog = false
                this.editFlag = false

                this.$store
                    .dispatch('partner_set', this.partnerCopy)
                    .then(res => {
                        if (res.data.success) {
                            console.log('partner_set success')
                            if (res.data.id) {
                                this.partnerCopy.id = res.data.id
                                for (let user of this.partnerCopy.users) {
                                    user.partner_id = res.data.id
                                }
                            }

                            this.currentPartner.name = this.partnerCopy.name
                            this.currentPartner.address = this.partnerCopy.address
                            this.currentPartner.ordersToday = 0
                            this.currentPartner.ordersYesterday = 0
                            this.currentPartner.ordersMonth = 0
                            this.currentPartner.users = this.partnerCopy.users.sort(this.sortUsers)
                            for (let user of this.currentPartner.users) {
                                this.currentPartner.ordersToday += user.ordersToday
                                this.currentPartner.ordersYesterday += user.ordersYesterday
                                this.currentPartner.ordersMonth += user.ordersMonth
                            }
                            if (this.createFlag) {
                                this.createFlag = false
                                this.partners.push(this.currentPartner)
                                this.partners = this.partners.sort(this.sortPartners)
                            }

                            this.unallocatedUsers = this.unallocatedUsersCopy.sort(this.sortUsers)
                            let partnersSave = []
                            for (let user of this.unallocatedUsers) {
                                let puser = this.partner_users.find(puser => puser.id == user.id)
                                if (puser) {
                                    puser.partner_id = 0
                                    delete puser.ordersToday
                                    delete puser.ordersYesterday
                                    delete puser.ordersMonth
                                    partnersSave.push(puser)
                                }
                            }
                            console.log('this.partnerCopy', this.partnerCopy)
                            for (let user of this.partnerCopy.users) {
                                let puser = this.partner_users.find(puser => puser.id == user.id)
                                if (puser) {
                                    puser.partner_id = this.partnerCopy.id
                                    delete puser.ordersToday
                                    delete puser.ordersYesterday
                                    delete puser.ordersMonth
                                    partnersSave.push(puser)
                                }
                            }
                            console.log('partnersSave', partnersSave)

                            this.$store
                                .dispatch('users_set', partnersSave)
                                .then(res => {
                                    if (res.data.success) {
                                        console.log('users_set success')
                                    } else {
                                        console.log('users_set not success')
                                    }
                                    this.loading = false
                                })
                                .catch(err => {
                                    console.log('users_set error', err)
                                    this.loading = false
                                })
                        } else {
                            console.log('partner_set not success')
                        }
                    })
                    .catch(err => {
                        console.log('partner_set error', err)
                        this.loading = false
                    })

            },
            onClickDelete() {
                this.loading = true
                this.partnerDialog = false
                this.editFlag = false
                let unallocated = []
                for (let user of this.partnerCopy.users) {
                    let puser = this.partner_users.find(puser => puser.id == user.id)
                    if (puser) {
                        puser.partner_id = 0
                        this.unallocatedUsers.push(JSON.parse(JSON.stringify(puser)))
                        delete puser.ordersToday
                        delete puser.ordersYesterday
                        delete puser.ordersMonth
                        unallocated.push(puser)
                    }
                }
                console.log('unallocatedUsers', this.unallocatedUsers)
                console.log('unallocated', unallocated)
                this.$store
                    .dispatch('partner_delete', this.partnerCopy.id)
                    .then(res => {
                        if (res.data.success) {
                            console.log('partner_delete success')
                            this.$store
                                .dispatch('users_set', unallocated)
                                .then(res => {
                                    if (res.data.success) {
                                        console.log('users_set success')
                                    } else {
                                        console.log('users_set not success')
                                    }
                                    this.loading = false
                                })
                                .catch(err => {
                                    this.loading = false
                                    console.log('users_set error', err)
                                })
                        } else {
                            console.log('partner_delete not success')
                        }
                    })
                    .catch(err => {
                        this.loading = false
                        console.log('partner_delete error', err)
                    })
                this.partners = this.partners.filter(partner => partner.id != this.partnerCopy.id)
            },
            onClickEdit(partner) {
                this.currentPartner = partner
                
                this.unallocatedUsers = this.unallocatedUsers.sort(this.sortUsers)

                this.unallocatedUsersCopy = JSON.parse(JSON.stringify(this.unallocatedUsers))
                this.partnerCopy = JSON.parse(JSON.stringify(partner))

                this.editFlag = true
                this.dialogHeader = `Партнер ${partner.name}`
                this.partnerDialog = true
                // this.addressResult = ''
                this.onChangeAddress()
            },
            onClickCreate() {
                this.currentPartner = {
                    id: null,
                    name: '',
                    address: '',
                    status: 0,
                    dispatcher_tid: this.partners[0].dispatcher_tid,
                    users: [],
                    tnames: '',
                    ordersToday: 0,
                    ordersYesterday: 0,
                    ordersMonth: 0
                }
                this.addressResult = 'Введите адрес'
                this.addressColor = 'grey'
                
                this.unallocatedUsers = this.unallocatedUsers.sort(this.sortUsers)

                this.unallocatedUsersCopy = JSON.parse(JSON.stringify(this.unallocatedUsers))
                this.partnerCopy = JSON.parse(JSON.stringify(this.currentPartner))

                this.createFlag = true
                this.dialogHeader = `Новый партнер`
                this.partnerDialog = true
            },
            addressUpdate() {
                this.addressUpdateFlag = true
                let partner_id = 0
                const partner = this.partners.find(partner => partner.tid == this.partnerCopy.tid)
                if (partner) {
                    partner_id = partner.partner_id
                }
                console.log('partner_id,', partner_id)
                console.log('this.partnerCopy', this.partnerCopy)

                this.$store
                    .dispatch('partner_address', { partner_id, address: this.partnerCopy.address })
                    .then(res => {
                        console.log('res,', res)

                        if (res.data.success) {
                            this.addressResult = 'Адрес найден'
                            this.addressColor = 'green'
                        } else {
                            this.addressResult = 'Адрес не найден'
                            this.addressColor = 'red'
                        }

                        this.addressUpdateFlag = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.addressResult = 'Адрес не найден'
                        this.addressColor = 'red'
                        this.addressUpdateFlag = false
                    })
            },
            onClickAddressUpdate() {
                if (this.timerAddressUpdate) {
                    clearTimeout(this.timerAddressUpdate)
                    this.timerAddressUpdate = null
                }
                this.addressUpdateFlag = true
                this.addressResult = 'Поиск...'
                this.addressColor = 'grey'
                this.addressUpdate()
            },
            onChangeAddress() {
                // тут так должно быть!
                this.addressUpdateFlag = true
                this.addressResult = 'Поиск...'
                this.addressColor = 'grey'
                if (this.timerAddressUpdate) {
                    clearTimeout(this.timerAddressUpdate)
                    this.timerAddressUpdate = null
                }
                this.timerAddressUpdate = setTimeout(() => {
                    this.timerAddressUpdate = null
                    this.addressUpdateFlag = true
                    this.addressResult = 'Поиск...'
                    this.addressColor = 'grey'
                    this.addressUpdate()
                }, 2000)
            },
            onClickUpdate() {
                this.updateData()
            },
            getConnectColor(status) {
                return status == 1 ? '#4CAF50' : '#F44336'
            },
            getConnectStatus(status) {
                return status == 1 ? 'Подключен' : 'Отключен'
            },
            openAll() {
                for (const partner of this.partners) {
                    this.$set(this.$refs.dTable.expansion, partner.id, true)
                }

            },
            closeAll() {
                for (const partner of this.partners) {
                    this.$set(this.$refs.dTable.expansion, partner.id, false)
                }
            },
            updatePaginate() {

            },
            sortPartners(a, b) {
                if (a.name > b.name) {
                    return 1
                }
                if (a.name < b.name) {
                    return -1
                }
                if (a.address > b.address) {
                    return 1
                }
                if (a.address < b.address) {
                    return -1
                }
                return 0
            },
            sortUsers(a, b) {
                if (a.tname > b.tname) {
                    return 1
                }
                if (a.tname < b.tname) {
                    return -1
                }
                return 0
            },
            updateData() {
                this.loading = true
                this.$store
                    .dispatch('partners_get')
                    .then(res => {

                        if (res.data.success) {

                            let partners = res.data.partners.sort(this.sortPartners)

                            console.log('partners', partners)

                            this.$store
                                .dispatch('partner_users')
                                .then(res => {
                                    if (res.data.success) {

                                        this.usersCount = 0
                                        this.usersCountOnline = 0

                                        this.partner_users = res.data.users.filter(user => user.role == 3)
                                        console.log('partner_users', this.partner_users)
                                        this.partners = partners.map(p => {
                                            let partner = p
                                            partner.users = []
                                            partner.tnames = ''
                                            partner.ordersToday = 0
                                            partner.ordersYesterday = 0
                                            partner.ordersMonth = 0
                                            for (const user of this.partner_users) {
                                                if (!user.ordersToday) user.ordersToday = 0
                                                if (!user.ordersYesterday) user.ordersYesterday = 0
                                                if (!user.ordersMonth) user.ordersMonth = 0
                                                if (partner.id == user.partner_id) {
                                                    partner.users.push(user)
                                                    partner.tnames += user.tname + ', '
                                                    partner.ordersToday += user.ordersToday
                                                    partner.ordersYesterday += user.ordersYesterday
                                                    partner.ordersMonth += user.ordersMonth
                                                }
                                            }
                                            if (partner.users) {
                                                this.usersCount += partner.users.length
                                                this.usersCountOnline += partner.users.filter(user => user.enable == 1).length
                                                partner.users = partner.users.sort(this.sortUsers)
                                            }
                                            return partner
                                        })

                                        this.unallocatedUsers = res.data.users.filter(user => user.role == 3 && !user.partner_id)
                                        this.unallocatedUsers = this.unallocatedUsers.map(user => {
                                            user.ordersMonth = 0
                                            user.ordersToday = 0
                                            user.ordersYesterday = 0
                                            return user
                                        }).sort(this.sortUsers)
                                        this.usersCount += this.unallocatedUsers.length
                                        this.usersCountOnline += this.unallocatedUsers.filter(user => user.enable == 1).length

                                        console.log('unallocatedUsers', this.unallocatedUsers)


                                    }
                                    this.loading = false
                                })
                                .catch(err => {
                                    this.loading = false
                                    console.log(err)
                                })
                        }

                    })
                    .catch(err => {
                        this.loading = false
                        console.log(err)
                    })
            },orderStatusColorHtml(sta) {
                let color = 'grey'
                switch (sta) {
                    case config.order.status.new:
                        color = '#9C27B0' // purple
                        break
                    case config.order.status.way_partner:
                        color = '#FF9800' // orange
                        break
                    case config.order.status.way_guest:
                        color = '#2196F3' // blue
                        break
                    case config.order.status.complete:
                        color = '#4CAF50' // green
                        break
                    case config.order.status.forming:
                        color = '#607D8B' // blue-grey
                        break
                    case config.order.status.passed:
                        color = '#F44336' // red
                        break
                }
                return color
            },
            orderStatusColor(sta) {
                let color = 'grey'
                switch (sta) {
                    case config.order.status.new:
                        color = 'purple'
                        break
                    case config.order.status.way_partner:
                        color = 'orange'
                        break
                    case config.order.status.way_guest:
                        color = 'blue'
                        break
                    case config.order.status.complete:
                        color = 'green'
                        break
                    case config.order.status.forming:
                        color = 'grey'
                        break
                    case config.order.status.passed:
                        color = 'red'
                        break
                }
                return color + '--text'
            },
            orderStatusIcon(sta) {
                let icon = 'mdi-question'
                switch (sta) {
                    case config.order.status.new:
                        icon = 'mdi-flash'
                        break
                    case config.order.status.way_partner:
                        icon = 'mdi-run-fast'
                        break
                    case config.order.status.way_guest:
                        icon = 'mdi-run-fast'
                        break
                    case config.order.status.complete:
                        icon = 'mdi-check-bold'
                        break
                    case config.order.status.forming:
                        icon = 'mdi-account-question'
                        break
                    case config.order.status.passed:
                        icon = 'mdi-arrow-right-bold'
                        break
                }
                return icon
            },
            orderStatusStr(sta) {
                let status = 'Неизвестно'
                switch (sta) {
                    case config.order.status.new:
                        status = 'Новый'
                        break
                    case config.order.status.way_partner:
                        status = 'В пути к партнеру'
                        break
                    case config.order.status.way_guest:
                        status = 'В пути к гостю'
                        break
                    case config.order.status.complete:
                        status = 'Доставлен'
                        break
                    case config.order.status.forming:
                        status = 'Создается'
                        break
                    case config.order.status.passed:
                        status = 'Передан курьеру'
                        break
                }
                return status
            },
        },
    }
</script>

<style lang="stylus">

    .partners
        height 100%

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    padding 0 8px
            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                .v-data-table__expanded__row:hover
                    background #e4e4e4 !important
                    cursor pointer !important

                td
                    font-size 0.75rem
                    padding 0 8px
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px

        .expanded-content
            box-shadow inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75), inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75)
            background none !important
            &:hover
                cursor default !important

        .th-first-item
            width 30%

        b
            font-weight 500

    .partners-dialog
        b
            font-weight 500
        .v-list
            overflow-y auto
            height 132px
            .list-group
                height 100%
                .v-list-item
                    cursor pointer
                    &:hover
                        background-color beige
                .sortable-ghost
                    background-color beige

</style>
